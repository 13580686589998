<template>
  <div>
    <br />
    <div class="row">
      <div class="col-3">
        <label for=""> Prénom </label>
        <input type="text" v-model="customer.firstName" class="form-control" />
      </div>

      <div class="col-3">
        <label for=""> Nom </label>
        <input type="text" v-model="customer.lastName" class="form-control" />
      </div>

      <div class="col-3">
        <label for="">CIN</label>
        <input type="text" v-model="customer.cin" class="form-control" />
      </div>

      <div class="col-3">
        <label for="">Phone</label>
        <input type="text" v-model="customer.phone" class="form-control" />
      </div>
    </div>

    <br />
    <div class="row">
      <div class="col-3">
        <label for="">E-mail</label>
        <input type="text" v-model="customer.email" class="form-control" />
      </div>

      <div class="col-3">
        <label for="">Nom Comerciale</label>
        <input type="text" v-model="customer.society" class="form-control" />
      </div>

       <div class="col-3">
        <label for="">ICE</label>
        <input type="text" v-model="customer.ice" class="form-control" />
      </div>

      <div class="col-3">
        <label for="">Type</label>
        <select
          name=""
          id=""
          v-model="customer.customerType_reference"
          class="form-select"
        >
          <option
            v-for="(customer_type, index) in customer_types"
            :key="index++"
            :value="customer_type.reference"
          >
            {{ customer_type.fullName }}
          </option>
        </select>
      </div>

      <div class="col-3">
        <label for="">État</label>
        <select v-model="customer.blocked" class="form-select">
          <option :value="true">Bloqué</option>
          <option :value="false">Débloqué</option>
        </select>
      </div>
    </div>

    <div class="row mt-3 justify-content-between">
      <div class="col-auto"></div>
      <div class="col-auto">
        <button class="btn btn-success" @click="save(customer)">
          <i class="bi bi-check-square-fill"></i> Modifer & Enregistrer
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      // customer: {},
    };
  },
  computed: {
    ...mapGetters("customer", {
      customer_types: "getCustomerTypes",
      customer: "getCustomer",
    }),
  },
  methods: {
    async save(data) {
      await this.$store.dispatch("customer/update", data);
    },
    async empty() {
      this.$store.commit("customer/setCustomer", {});
    },
  },
  beforeMount() {
    this.$store.dispatch("customer/getCustomerTypes");
    this.$store.dispatch("customer/show", this.$route.params.reference);
  },
};
</script>
